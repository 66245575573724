<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <full-page-content-container>
          <company-info-details-header
            v-if="companyInfo !== null"
            :company-info="companyInfo"
          ></company-info-details-header>

          <grid-table
            v-if="companyInfo && companyCalculation"
            :column-defs="columnDefs"
            :row-data="rowData"
          >
            <template v-slot:cell-renderer="slotProps">
              <div>
                <span class="icon-button mr-1"
                  ><img
                    :src="require('@/assets/images/icons/edit.svg')"
                    alt="edit icon"
                /></span>
                <span
                  class="icon-button mr-1"
                  @click="
                    viewCompanyModels(slotProps.currentRowData.financial_model)
                  "
                  ><img
                    :src="require('@/assets/images/icons/view.svg')"
                    alt="view icon"
                /></span>
                <span class="icon-button" @click="confirmDeleteFM()"
                  ><img /><img
                    :src="require('@/assets/images/icons/delete.svg')"
                    alt="delete icon"
                /></span>
              </div>
            </template>
          </grid-table>
          <empty-dataset v-else></empty-dataset>
        </full-page-content-container>
      </div>
    </div>
  </div>
</template>

<script>
import FullPageContentContainer from "../../../layouts/components/fullPage/FullPageContentContainer";
// import company_info from "../users/db/company_info";
import GridTable from "../../../components/tables/GridTable";
import axios from "../../../../axios";
import CompanyInfoDetailsHeader from "../../company-info/components/CompanyInfoDetailsHeader";
import EmptyDataset from "../../../components/displays/EmptyDataset";
export default {
  name: "ViewCompanyInfo",
  components: {
    EmptyDataset,
    CompanyInfoDetailsHeader,
    GridTable,
    FullPageContentContainer,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Created",
          field: "created",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Period",
          field: "period",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Updated",
          field: "updated",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      companyInfo: null,
      companyCalculation: null,
      openDeletePopup: false,
      openDeleteFMPopup: false,
    };
  },
  methods: {
    goToEdit() {
      const companyId = this.$route.params.id;
      this.$router.push({
        name: "edit-company-info",
        params: { id: companyId },
      });
    },
    viewCompanyModels(modelID) {
      this.$router.push({
        name: "view-company-models",
        params: { id: modelID },
      });
    },
    getCompanyInfo() {
      const loading = this.$vs.loading();
      // First get the current user
      // Then get the company that belongs to that user
      // Then get the company models for that particular company
      // eslint-disable-next-line no-unused-vars
      this.$store
        .dispatch("getUserCompany", this.$route.params.id)
        .then((data) => {
          loading.close();
          this.companyInfo = data;
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    showAllCompanyCalculation() {
      if (this.companyInfo !== null) {
        axios
          .get(
            `${this.$store.state.server.requestUri}/admin/show-all-company-calculation/${this.companyInfo.id}`
          )
          .then((res) => {
            const data = res.data.data;
            if (data.calculation.length > 0) {
              this.companyCalculation = true;
              // const dataid = data.calculation[0][0].calculated.id;
              for (let index = 0; index < data.calculation[0].length; index++) {
                const element = {
                  financial_model: data.calculation[0][index].calculated.id,
                  created: this.formatDate(
                    data.calculation[0][index].calculated.created_at
                  ),
                  period:
                    data.calculation[0][index].calculated.nwc_calculation[0][0]
                      .period,
                  updated: this.formatDate(
                    data.calculation[0][index].calculated.updated_at
                  ),
                };
                this.rowData.push(element);
              }
              console.log(data.calculation[0][0].calculated);
            }
          })
          .catch((err) => console.log(err));
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [day, month, year].join("-");
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete() {
      this.openDeletePopup = true;
    },
    cancelDeleteFM() {
      this.openDeleteFMPopup = false;
    },
    confirmDeleteFM() {
      this.openDeleteFMPopup = true;
    },
  },
  watch: {
    companyInfo() {
      this.showAllCompanyCalculation();
    },
  },
  created() {
    this.getCompanyInfo();
    this.showAllCompanyCalculation();
  },
};
</script>

<style scoped>
.no-calculation {
  text-align: center;
  width: 100%;
}
</style>
