<template>
  <div>
    <div class="ci__details">
      <div class="ci__logo">
        <img src="~@/assets/images/logo_primary@2x.png" alt="Company Logo" />
      </div>
      <div class="ci__content">
        <p>
          <span>{{ "Company Name: " }}</span> {{ companyInfo.name }}
        </p>
        <p><span>Email Address:</span> {{ companyInfo.email }}</p>
        <p><span>User:</span> {{ user.name }}</p>
        <p><span>Country:</span> {{ companyInfo.country }}</p>
        <p><span>State:</span>{{ companyInfo.state }}</p>
        <p><span>City:</span> {{ companyInfo.city }}</p>
        <p><span>Created At:</span> {{ companyInfo.created_at.split('T')[0] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CompanyInfoDetailsHeader",
  props: {
    companyInfo: {
      required: true,
    },
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    getUser() {
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/user/${this.companyInfo.user_id}`
        )
        .then((res) => {
          this.user = res.data.data.user;
          console.log(this.user);
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    console.log("This has been executed");
    this.getUser();
  },
};
</script>

<style scoped lang="scss">
.ci {
  &__container {
  }
  &__details {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 15rem;
    grid-gap: 2rem;
    padding-bottom: 4rem;
    border-bottom: 3px solid #f9f9f9;
    margin-bottom: 4rem;
  }
  &__logo {
    background-color: #f9f9f9;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 11rem;
      height: 5rem;
    }
  }
  &__content {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, 1fr);
    & > p {
      font-weight: 300;
      & > span {
        display: inline-block;
        margin-right: 1rem;
        font-weight: 500;
      }
    }
  }
}
</style>
